const convert = {
  arr2cascader_deviceconfig(data) {
    const result = [];
    if (!Array.isArray(data)) {
      return result;
    }
    const map = {};
    data.forEach((item) => {
      map[item.id] = item;
      item.label = item.name;
      item.value = item.id;
    });
    data.forEach((item) => {
      const parent = map[item.parentId];
      if (parent) {
        (parent.children || (parent.children = [])).push(item);
      } else {
        result.push(item);
      }
    });
    return result;
  },
};

export default convert;
